import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34d3ccf6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "title"]
const _hoisted_2 = { class: "info" }
const _hoisted_3 = { class: "hashtags" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["modal", { open: _ctx.isOpen }]),
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closeModal()))
  }, [
    (_ctx.post)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("a", {
            class: "close-icon",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
          }, "X"),
          _createElementVNode("img", {
            src: _ctx.post.image_url,
            title: _ctx.post.pic_text
          }, null, 8, _hoisted_1),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("small", null, _toDisplayString(_ctx.toDate(_ctx.post.date_time_posted)), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.getDescriptionWithoutTags(_ctx.post.pic_text)), 1),
            _createElementVNode("span", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getTagsFromDescription(_ctx.post.pic_text), (tag) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  _createElementVNode("a", {
                    href: 'https://www.instagram.com/explore/tags/' + tag,
                    target: "blank"
                  }, _toDisplayString("#" + tag), 9, _hoisted_4),
                  _createTextVNode("   ")
                ], 64))
              }), 256))
            ])
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}