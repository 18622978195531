<template>
  <div class="post">
    <img
      :src="post.thumbnail_url"
      :title="post.pic_text"
      @click="openInModal()"
    />
    <div class="text">
      <small>{{ toDate(post.date_time_posted) }} - {{ post.pic_text }}</small>
    </div>
  </div>
</template>

<script lang="ts">
import { SociableKitInstagramPost } from "@/types/SociableKitInstagramPost";
import { defineComponent, ref, toRefs } from "vue";
import stringMixins from "@/mixins/stringMixins";
import Modal from "./Modal.vue";

export default defineComponent({
  name: "PostDisplay",
  props: {
    post: {
      type: SociableKitInstagramPost,
      required: true,
    },
    modal: {
      type: Modal,
      required: true,
    },
  },
  setup(props) {
    const { post, modal } = toRefs(props);

    const toDate = stringMixins.toDate;

    const openInModal = () => {
      modal.value.openModal(post.value);
    };

    return {
      openInModal,
      toDate,
    };
  },
});
</script>

<style scoped lang="scss">
.post {
  transition: transform 200ms ease;

  border: 8px solid $primary;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  background-color: rgba($primary, 0.9);
  min-width: 200px;
  min-height: 200px;

  &:hover {
    transform: scale(1.05);
  }

  img {
    object-fit: cover;
    height: 100%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .text {
    background-color: rgba($primary, 0.8);
    position: absolute;
    left: 0;
    bottom: 0;
    color: $textSecondary;
    font-size: 1rem;
    width: calc(100% - 10px);
    padding: 5px;

    small {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
